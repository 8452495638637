import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import components from '../content/common'
import Navbar from '../components/elements/Navbar'
import Footer from '../components/elements/Footer'
//import VideoPlayer from '../components/modules/VideoPlayer'
import ContestFormWackelkontakt from '../components/elements/ContestFormWackelkontakt'

//import YtPoster from '@images/contest/oimara-wackelkontakt.jpg'
import imageVisual from '@images/contest/visual-wackelkontakt.jpg'

const Headline = tw.h1`font-DIN text-white md:text-[40px] leading-[38px] text-3xl pt-5`

const Subtitle = tw.h2`font-DIN text-white md:text-[32px] leading-[38px] text-2xl pt-2`

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-30 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    ${tw`text-18px`}
    ${tw`font-bold`}

    &:before {
      content: '>';
    }

    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const VideoWrap = tw.div`relative max-w-master mx-auto w-full px-5 lg:px-0`
const IframeWrap = styled.div`
  ${tw`relative overflow-hidden`}
  aspect-ratio: 16 / 9;

  .play {
    ${tw`ml-2 transition duration-300 w-[25px] h-[25px] border-t-[15px] border-r-0 border-b-[15px] border-l-[25px] sm:w-[45px] sm:h-[45px] sm:border-t-[30px] sm:border-r-0 sm:border-b-[30px] sm:border-l-[45px]`}
    border-color: transparent transparent transparent #006e42;
  }
  .wrap-play {
    ${tw`absolute flex items-center justify-center transition duration-300 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full shadow-2xl cursor-pointer w-18 h-18 sm:w-28 sm:h-28 top-1/2 left-1/2`}

    &:hover {
      ${tw`bg-radler`}

      .play {
        border-color: transparent transparent transparent #fff;
      }
    }
  }
`

const Visual = styled.img``

const ContentWrap = tw.div`max-w-master mx-auto`

const DartPage = () => {
  const [confirmation, setConfirmation] = useState(false)

  return (
    <>
      <Navbar content={components.navbar} />
      <Section>
        <ContentWrap>
          <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
            <Visual src={imageVisual} />
          </div>
          <br />
          <div tw="max-w-screen-md mx-auto px-3 sm:px-7 text-center">
            <Headline>Jetzt mitmachen und eine legendäre Party erleben!</Headline>
            <p>
              Wir verlosen <strong>17 x 2 Tickets für das <br />Winter Open Air am 21.03.2025 - Almrausch Planai</strong>
            </p>
            <p>
              Erlebe den <strong>Oimara live</strong> mit seinem Hit <strong>"Wackelkontakt"</strong>
            </p>
            <p>
              Inklusive 2 Übernachtungen mit Frühstück im Hotel ERDINGER Weißbräu und einer Brauereiführung
            </p>
            <br />
            <VideoWrap>
              <IframeWrap className="aspect-w-16 aspect-h-9">
                <iframe
                  className="w-full h-full mb-2"
                  title="OIMARA - WACKELKONTAKT"
                  src="https://www.youtube-nocookie.com/embed/1SXQfd8f-o0?rel=0&autoplay=1"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
              </IframeWrap>
            </VideoWrap>
            <br />
            <Subtitle>Und so läufts ab:</Subtitle>
            <p>
              Donnerstag, 20.03. Eigene Anreise nach Erding inkl. Brauereiführung<br />
              Freitag, 21.03. Fahrt mit dem Partybus von Erding nach Schladming, wir feiern mit dem Oimara vor atemberaubender Berg-Kulisse!<br />
              Samstag, 22.03. Eigene Abreise
            </p>
            <br />

            {confirmation ? (
              <p>
                Das Formular wurde erfolgreich abgesendet.
              </p>
            ) : (
              <>
                <ContestFormWackelkontakt onClick={() => setConfirmation(true)} />
              </>
            )}
          </div>
        </ContentWrap>
      </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default DartPage
